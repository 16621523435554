import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import slugify from "slugify"
import { ThemeProvider } from "styled-components"
import theme from "../utils/theme"
import {
  Layout,
  FormatRichText,
  EventCard,
  NavigationCard,
  ArticleCard,
  EventCardWLink,
} from "../components"
import * as S from "./styles/__index.styles"

const Home = ({ data, location }) => {
  const hub = data.contentfulWebsiteDetails
  const articleMix = []

  const flat = data.allContentfulYearlyTheme.nodes.flat()

  flat.forEach((parent) => {
    if (parent.article || parent.third_party_article) {
      parent?.article?.forEach((item) => articleMix.push(item))
      parent?.third_party_article?.forEach((item) => articleMix.push(item))
    }
  })

  const articles = articleMix
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .slice(0, 3)

  const latestChallenge = data.allContentfulChallenge?.nodes[0]

  return (
    <Layout location={location}>
      <ThemeProvider
        theme={{
          ...theme,
          buttonColor: `${(props) => props.theme.text06}`,
          buttonBackground: `${(props) => props.theme.uiBackground}`,
        }}
      >
        <S.Grid>
          {hub.homePageCta && (
            <>
              <S.Hero
                title={hub.homePageCta.title}
                buttonText={hub.homePageCta.buttonText}
                link={hub.homePageCta.url}
                desc={hub.homePageCta.shortDescription.shortDescription}
              />
              <S.Image image={hub.homePageImage && hub.homePageImage} />
            </>
          )}
          <S.Borderline />
          <S.Borderline />
          <FormatRichText width="608px" text={hub.statement?.json} />
          <S.SubGrid>
            {hub.topics?.map((topic, i) => {
              return (
                i < 4 && (
                  <NavigationCard
                    key={i}
                    title={topic.title}
                    desc={topic.description.description}
                    cta={topic.callToAction}
                    link={topic.link}
                  />
                )
              )
            })}
          </S.SubGrid>
          {hub.moocDetails && (
            <>
              <S.ChallengeGraphic image={hub.moocDetails?.imageTexture} />
              <S.LearningCard
                heading={hub.moocDetails?.heading}
                title={hub.moocDetails?.themeName}
                desc={hub.moocDetails?.description.description}
                cta={hub.moocDetails?.cta}
                link={
                  hub.moocDetails?.link === "#" ? "" : hub.moocDetails?.link
                }
              />
            </>
          )}

          <S.SubGrid lessThan2={articles?.length < 2}>
            {articles?.map((item, i) => {
              const link = item.url ? item.url : `voices/${item.slug}`
              const author = item.link
                ? `Article by ${item.author}`
                : item.author
              const desc =
                item.body?.json.content.find((p) => p.nodeType === "paragraph")
                  .content[0].value || item.shortSummary.shortSummary
              return i === 0 ? (
                <S.ArticleCardExt
                  key={i}
                  title={item.title}
                  link={link}
                  author={author}
                  img={item.thumbnail}
                  desc={desc}
                  landscape
                />
              ) : (
                <ArticleCard
                  key={i}
                  title={item.title}
                  link={link}
                  author={author}
                  img={item.thumbnail}
                />
              )
            })}
          </S.SubGrid>
          <S.Borderline />
          <S.Borderline />
          <S.EventCardWrapper>
            {latestChallenge?.keyDates?.map((keydate, i) => {
              return keydate.event ? (
                <EventCardWLink
                  date={keydate.date}
                  title={keydate.title}
                  link={`events/${keydate.event.slug}`}
                  key={i}
                  lowerTheTag
                />
              ) : (
                <EventCard
                  date={keydate.date}
                  title={keydate.title}
                  key={i}
                  lowerTheTag
                />
              )
            })}
          </S.EventCardWrapper>
          {hub.newsletterCta && (
            <>
              <S.SignUpCard
                heading={hub.newsletterCta.heading}
                title={hub.newsletterCta.title}
                buttonText={hub.newsletterCta.buttonText}
                link={hub.newsletterCta.url}
                desc={hub.newsletterCta.shortDescription.shortDescription}
              />
              <S.BackgroundImage
                image={hub.homePageTextureImage && hub.homePageTextureImage}
              />
            </>
          )}
          <S.PartnerLogosContainer>
            {hub.partners?.map((partner, i) => (
              <S.PartnerLogosWrapper key={i}>
                <S.PartnerLogo
                  src={partner?.logo?.resize?.src}
                  alt={partner?.logo?.description}
                />
              </S.PartnerLogosWrapper>
            ))}
          </S.PartnerLogosContainer>
        </S.Grid>
      </ThemeProvider>
    </Layout>
  )
}

Home.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.shape({
    contentfulWebsiteDetails: PropTypes.object,
    article: PropTypes.arrayOf(PropTypes.object),
    allContentfulYearlyTheme: PropTypes.object,
    allContentfulChallenge: PropTypes.object,
  }).isRequired,
}

export default Home

export const pageQuery = graphql`
  query {
    contentfulWebsiteDetails {
      statement {
        json
      }
      topics {
        title
        link
        callToAction
        description {
          description
        }
      }
      moocDetails {
        heading
        themeName
        description {
          description
        }
        imageTexture {
          description
          fluid(maxWidth: 656, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        cta
        link
      }
      homePageCta {
        title
        buttonText
        url
        shortDescription {
          shortDescription
        }
      }
      newsletterCta {
        heading
        title
        buttonText
        url
        shortDescription {
          shortDescription
        }
      }
      homePageImage {
        description
        fluid(maxWidth: 656, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      partners {
        logo {
          description
          resize(height: 121, quality: 100) {
            src
          }
        }
      }
      homePageTextureImage {
        description
        fluid(maxWidth: 1312, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulYearlyTheme(sort: { fields: [updatedAt], order: DESC }) {
      nodes {
        article {
          title
          author
          slug
          body {
            json
          }
          updatedAt
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
        third_party_article {
          title
          author
          url
          shortSummary {
            shortSummary
          }
          updatedAt
          thumbnail {
            description
            fluid(maxWidth: 591, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulChallenge {
      nodes {
        year
        themeName
        keyDates {
          title
          date
          event {
            slug
          }
        }
      }
    }
  }
`
