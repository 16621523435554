import media from "styled-media-query"
import styled from "styled-components"
import {
  CTACard,
  Banner,
  FormatRichText,
  EventCard,
  NavigationCard,
  ArticleCard,
  EventCardWLink,
} from "../../components"

// 1312px refers to the containers max width
// medium is 768px
// 414px is the largest mobile screen
// 1056px is the min container width of the designs.

export const Hero = styled(CTACard)`
  div:first-of-type {
    padding: 2rem;
  }
  ${media.greaterThan("medium")`
    grid-column: 1 / span 2;
    div:first-of-type {
      border-right: ${(props) => props.theme.borderStyle};
    }
  `}
`
export const SignUpCard = styled(CTACard)``
export const NavigationCardExt = styled(NavigationCard)``
export const ArticleCardExt = styled(ArticleCard)``
export const Image = styled(Banner)``
export const BackgroundImage = styled(Banner)``
export const ChallengeGraphic = styled(Banner)``
export const LearningCard = styled(NavigationCard)``
export const EventCardWrapper = styled.div``
export const PartnerLogo = styled.img`
  max-height: 40px;
  max-width: 250px;
`
export const PartnerLogosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2.5rem 0rem;
  flex-grow: 1;
`
export const PartnerLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SubGrid = styled.div`
  display: grid;
  grid-template-columns: none;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  ${NavigationCard}, ${NavigationCardExt}, ${ArticleCard}, ${ArticleCardExt} {
    border-bottom: ${(props) => props.theme.borderStyle};
    a {
      padding: 1rem;
    }
  }
  ${NavigationCard}, ${NavigationCardExt} {
    background-color: ${(props) => props.theme.ui03};
  }
  ${media.greaterThan("medium")`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  ${NavigationCard}, ${NavigationCardExt} {
    a {
      padding: 2rem;
    }
  }
  ${ArticleCard}, ${ArticleCardExt} {
    a { 
      padding: 2rem;
    }
    &:first-child {
      min-height: 500px;
    }
  }
  ${NavigationCard} {
    grid-column: 1 1;
    grid-row: span 1;
  }
  ${NavigationCardExt} {
    grid-column: 2 / span 1;
    grid-row: 1 / -1;  
    a {
      border-left: ${(props) => props.theme.borderStyle};
    }
  }
  ${ArticleCard} {
    grid-column: 2 /span 1;
    grid-row: span 1;
    a {
      border-right: ${(props) => props.theme.borderStyle};
    }
  }
  ${ArticleCardExt} {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2; 
    a {
      border-left: none;
    }
  }
`}
  ${media.greaterThan("1055px")`
    ${NavigationCard}, ${NavigationCardExt} {
      a {
        > * {
          max-width: 75%;
        }
      }
    }
  `}
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: none;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  ${Hero}, ${FormatRichText}, ${EventCard}, ${EventCardWLink}, ${Image}, ${BackgroundImage}, ${ChallengeGraphic} {
    grid-row: span 1;
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${LearningCard} {
    background-color: ${(props) => props.theme.ui08};
    border-bottom: ${(props) => props.theme.borderStyle};
    a {
      padding: 2rem;
    }
  }
  ${FormatRichText} {
    padding: 2.5rem 1rem;
    display: flex;
    align-items: center;
    p,
    span {
      font-family: ${(props) => props.theme.fontStylePrimary};
      font-size: 1.5rem;
      line-height: 1.625rem;
      text-align: center;
      margin-bottom: 0rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  ${Image}, ${BackgroundImage}, ${ChallengeGraphic} {
    min-height: 320px;
    height: 100%;
  }
  ${BackgroundImage} {
    border-top: ${(props) => props.theme.borderStyle};
  }
  ${EventCard}, ${EventCardWLink} {
    background-color: ${(props) => props.theme.ui03};
    h2 {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    h1 {
      padding-top: 1rem;
    }
  }
  ${EventCardWLink}, ${EventCard} {
    padding-top: 0rem;
    height: auto;
    a,
    div:first-of-type {
      position: relative;
      padding: 1rem;
    }
  }
  ${SignUpCard} {
    div:first-of-type {
      padding: 2rem 1rem;
    }
  }
  ${PartnerLogosWrapper} {
    width: 100%;
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${media.greaterThan("414px")`
    ${Hero}, ${SignUpCard} {
        h2, p {
          max-width: 53%;
        }
      }
      ${LearningCard} {
        a {
          > * {
            max-width: 75%;
          }
        }
      }
    }
    ${PartnerLogosWrapper} {
      width: 50%;
      &:not(:last-child):not(:nth-child(even)) {
        border-right: ${(props) => props.theme.borderStyle};
      }
    }
  `}
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
${Hero}, ${FormatRichText}, ${Image}, ${NavigationCard} {
  border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${LearningCard} {
    grid-column: span 2;
    a {
      padding: 2rem;
      border-left: ${(props) => props.theme.borderStyle};
    }
  }
  ${ChallengeGraphic} {
    border-bottom: ${(props) => props.theme.borderStyle};
    grid-column: span 2;
  }
  ${Image} {
    grid-column: 3 / span 2;
  }
  ${FormatRichText} {
    grid-column: 1 / span 4;
    padding: 3rem 0rem 4rem 0rem;
    p {
      max-width: 58%;
    }
  }
  ${SubGrid} {
    grid-column: span 4;
  }
  ${SignUpCard} {
    z-index: 1;
    grid-column: 2 / 4;
    grid-row: 11 / 12;
  }
  ${BackgroundImage} {
    grid-column: 1 / span 4;
    grid-row: 11 / 12;
    border-bottom: 0px;
    border-top: none;
  }
  ${EventCardWrapper} {
    grid-column: span 4;
    grid-row: span 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: ${(props) => props.theme.ui03};
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${EventCard}, ${EventCardWLink} {
    &:not(:nth-child(4)) {
      border-right: ${(props) => props.theme.borderStyle};
    }
    border-bottom: none;
  }
  ${EventCardWLink}, ${EventCard}  {
    a, div:first-of-type {
      padding: 1rem 2rem;
      height: 100%;
    }
  }
  ${PartnerLogosContainer} {
    grid-column: span 4;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  ${PartnerLogosWrapper} {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-right: ${(props) => props.theme.borderStyle};
    &:nth-child(even) {
      border-right: ${(props) => props.theme.borderStyle};
    }
  }
`}
${media.lessThan("415px")`
  ${EventCardWLink}, ${EventCard} {
    padding-top: 56.25%;
    height: auto;
    a, div:first-of-type {
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
  ${PartnerLogosWrapper} {
    width: 100%;
    border-right: 0rem !important;
  }
`}
  ${media.between("medium", "1055px")`
    ${Hero}, ${SignUpCard} {
      h2, p {
        max-width: 70%;
      }
    }
  `}
  ${media.greaterThan("1055px")`
    
    ${EventCardWLink}, ${EventCard} {
      padding-top: 56.25%;
      height: 0;
      a, div:first-of-type {
        position: absolute;
      }
    }
  `}
`
export const Borderline = styled.b`
  height: 4px;
  border-bottom: ${(props) => props.theme.borderStyle};
  grid-row: span 1;
  ${media.greaterThan("medium")`
    grid-column: span 4;
  `}
`
